.edit-banner {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 450px;
  width: 100%;

  .banner-edit-group {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    .banner-edit-title {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: #000000;
      margin-bottom: 10px;
    }

    .banner-edit-input {
      width: 860px;
    }


    .banner-edit-textarea {
      width: 860px;
      height: 132px;
    }


    @media (max-width: 475px) {
      .banner-edit-input {
        width: 95vw;
      }

      .banner-edit-textarea {
        width: 95vw;
        height: 132px;
      }


    }
  }

  .banner-edit-btns{
    .banner-btn{
      margin-right: 10px;
    }
  }

  @media (max-width: 475px) {
    .banner-edit-btns{

      .banner-btn{
        width: 95vw;
        margin-bottom: 8px;
      }
    }


  }
}

.editBanner__modalOverlay {
	z-index: 99999999;
}
