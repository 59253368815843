@import 'src/styles/variables'

.auth
	&-wrapper
		background: url('../../assets/new-back.svg')
		background-size: cover
		object-fit: cover
		min-height: 99vh
		min-width: 99vw
		width: 100%
		height: 100%
		display: flex
		flex-direction: column
		align-items: center
		justify-content: center

.btnBack
	min-width: 87px
	padding: 9px 18px
	background: #51B7E9
	border-radius: 7px
	font-family: 'Montserrat'
	font-style: normal
	font-weight: 500
	font-size: 16px
	line-height: 23px
	color: #FFFFFF
	cursor: pointer
	transition: 0.5s
	box-sizing: border-box

	&:hover
		background: #1F9DDB
		border: 1px solid white

.zumzak-is
	font-size: 50px
	line-height: 50px

	&.register-step
		max-width: 573px
		width: 100%
		line-height: 36px

		span
			font-size: 30px

	span
		font-size: 35px

.auth-info
	span
		font-size: 20px
		line-height: 30px
		font-weight: 500
		display: block
		max-width: 650px

	img
		margin-top: 45px
		max-width: 628px
		max-height: 543px
		width: 100%
		height: 100%

.register-steps
	font-size: 20px
	line-height: 24px
	display: flex
	align-items: center
	flex-direction: column

	&__progress
		margin-top: 10px
		width: 250px
		height: 2px
		position: relative
		background: $white

		&.step-1, &.step-2
			&:before
				content: ''
				position: absolute
				top: -1px
				left: 0
				height: 4px
				background: #30A9E7
				transition: .3s ease-in

		&.step-1
			&:before
				width: 50%

		&.step-2
			&:before
				width: 100%

.login, .register
	max-width: 675px
	// min-width: 370px
	background: #32AAE5
	border-radius: 30px
	display: flex
	flex-direction: column
	align-items: center

	position: relative
	padding: 25px 50px
	// overflow: auto
	max-height: 100%
	margin: 10px auto
	@media (min-width: $xl)
		margin: 10px
	@media(max-width: 620px)
		padding: 25px 30px

.register
	&__title
		background: $green-light
		width: 245px
		height: 33px
		display: flex
		align-items: center
		justify-content: center
		font-size: 15px

	&__local-switcher
		position: absolute
		right: 20px
		top: 20px

		ul
			list-style: none

	&.store, &.requisites
		max-width: 100%
		width: 100%

		@media (min-width: $xxl)
			min-height: 718px

		form
			width: 100%

		.button
			margin-right: auto
			margin-left: auto
			margin-top: 10px
			display: block

.store
	&__upload-logo
		label
			display: block
			width: 144px
			height: 109px
			margin-top: 10px

			img
				width: 100%
				height: 100%
				object-fit: contain
				object-position: right

	&__subdomain
		display: block

		.input
			display: flex
			justify-content: space-between
			align-items: center

			// p
				// margin-left: 10px
				// height: 33px
				// box-shadow: 0 0 5px rgb(0 0 0 / 20%)
				// background: #ffffff
				// padding: 0 8px 0 6px
				// display: flex
				// align-items: center

	&__work-hours
		display: block

		.work-hour-item
			display: flex
			align-items: center

			&:not(:last-child)
				margin-bottom: 10px

			span
				width: 50px

			.inputs
				margin-left: 10px
				flex: 1

				label
					width: 50%

.requisites
	.button
		@media (min-width: $xxl)
			margin-top: 48px !important

.btnNewAuth
	min-width: 208px
	padding: 9px
	background: #51B7E9
	border-radius: 7px
	font-family: 'Montserrat'
	font-style: normal
	font-weight: 500
	font-size: 16px
	line-height: 23px
	color: #FFFFFF
	cursor: pointer
	transition: 0.5s
	box-sizing: border-box

	&:hover
		background: #1F9DDB
		border: 1px solid white

.btnNewAuthNext
	margin-top: 20px
	min-width: 108px
	padding: 9px
	background: #51B7E9
	border-radius: 7px
	font-family: 'Montserrat'
	font-style: normal
	font-weight: 800
	font-size: 18px
	line-height: 23px
	color: #FFFFFF
	cursor: pointer
	transition: 0.5s
	box-sizing: border-box
	border: 1px solid #FFFFFF
	box-shadow: inset 0px -4px 4px rgba(3, 0, 40, 0.05)

	&:hover
		background: #1F9DDB
		border: 1px solid white

.labelInp
	font-family: 'Montserrat'
	font-style: normal
	font-weight: 400
	font-size: 12px
	line-height: 30px
	color: #FFFFFF

.checkboxNew
	label
		div
			span
				border-radius: 4px

.selectAuth
	min-height: 48px !important

	border: none !important
	width: 100%
	div
		min-height: 48px
		border-radius: 10px !important

		span
			display: flex
			align-items: center
			font-family: 'Montserrat'
			font-style: normal
			font-weight: 400
			font-size: 18px
			line-height: 30px

.selectProduct
	// min-height: 48px !important

	// border: none !important
	// width: 100%
	div
		// min-height: 48px
		// border-radius: 10px !important

		span
			// display: flex
			// align-items: center
			// font-family: 'Montserrat'
			// font-style: normal
			// font-weight: 400
			// font-size: 18px
			// line-height: 30px
			input
				padding-left: 10px


.errorMessage1
	min-width: 142px
	padding: 8px 16px
	background: #78C2DD
	color: #FFFFFF
	border-radius: 2px
	animation: animateError 0.3s ease 0s 1 normal none
	position: absolute
	z-index: 2
	top: 1px
	right: 239px
	font-family: 'Montserrat'
	font-style: normal
	font-weight: 400
	text-align: center
	white-space: nowrap
	font-size: 11px
	@media(max-width: 1399.09px)
		right: 180px
	@media(max-width: 1199.09px)
		right: auto
		left: 0
	@media(max-width: 420px)
		font-size: 12px
		padding: 5px 7px

	&::after
		transform: rotate(180deg)
		content: ""
		position: absolute
		right: 12px
		top: 28px
		border: 10px solid transparent
		border-bottom: 10px solid#78C2DD
		@media(max-width: 1199.09px)
			right: auto
			left: 12px
		@media(max-width: 420px)
			top: 23px

.errorMessage2
	top: 1px
	right: -280px
	@media(max-width: 1199.09px)
		left: 9px
		right: auto

	&::after
		// right: 108px
		right: 298px

.selectInn
	font-weight: 400
	margin-bottom: 10px
	&:hover
		color: #32AAE5

.logo
	@media(max-width: 550px)
		margin-top: 70px

.logoStep1
	margin-top: 100px
	@media(max-width: 600px)
		margin-top: 120px

.step
	color: #32AAE5
