@import 'variables'

html, body
	font-family: $font-primary
	color: $deep-black
	font-size: 12px
	line-height: 20px
	font-weight: 700
	@media (min-width: $md)
		font-size: 14px

body
	overflow-y: scroll

main
	margin-top: 75px
	min-height: calc(100vh - 255px)

	&.nav
		margin-top: 130px

	@media (min-width: $xl)
		margin-top: 70px
		min-height: calc(100vh - 154px)

		&.nav
			margin-top: 70px

*
	box-sizing: border-box
	margin: 0
	padding: 0


a
	color: inherit
	text-decoration: none


button
	outline: none
	border: none
	background: none


hr
	height: 2px
	background-color: #E6EAF0
	border: 0
	border-radius: 5px

	&.color-blue
		background-color: $blue

	&.margin-10
		margin-top: 10px
		margin-bottom: 10px


::-webkit-scrollbar-track
	-webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 20%)
	background-color: $white

::-webkit-scrollbar-thumb
	background-color: $blue

::-webkit-scrollbar
	width: 4px
	height: 4px
	background-color: $white


input[type="number"]
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button
		-webkit-appearance: none


#toast-container
	position: fixed
	bottom: 10px
	right: 10px
	box-sizing: border-box
	z-index: 200
.toast
	padding: 15px 40px
	color: white
	width: 100%
	font-size: 20px
	max-width: 400px
	width: 100%
	text-align: center
	border-radius: 10px

.toast.error
	background-color: rgb(168, 0, 0)

.toast.succes
	background-color: rgb(19, 124, 19)


