$deep-black: #000000
$white: #ffffff
$sidebar: #C5E8F4
$blue: #006296
$blue-dark: #3b90af
$blue-deep: #0094E7
$peach: #F5AB92
$gray: #8d8d8d
$gray-light: #C4C4C4
$gray-background: #F7F7F7
$green: #48BB78
$green-light: #B3DE75
$danger: #e42323
$red: #EB2316
$shadow-primary: 0 0 5px rgba(0, 0, 0, 0.2)
$shadow-secondary: 0 0 10px rgb(94 94 94 / 0.4)
$shadow-danger: 0 0 5px rgb(255 0 0 / 0.4)

$font-primary: 'Montserrat', sans-serif

$xs: 375px
$sm: 576px
$md: 768px
$lg: 992px
$xl: 1200px
$xxl: 1400px
$xxxl: 1800px
