@import 'src/styles/variables'

.footer
	background: #220022
	color: white

	&-wrapper
		padding: 10px 0 20px
		display: flex
		flex-direction: column
		text-align: center
		justify-content: space-between
		@media (min-width: $xl)
			padding: 10px 0
			flex-direction: row
			align-items: center

		.left, .right
			@media (min-width: $xl)
				width: 35%

		.center
			@media (min-width: $xl)
				width: 30%

			span
				margin-top: 10px
				@media (min-width: $xl)
					margin-top: 0

		.left
			display: flex
			align-items: center
			flex-direction: column
			@media (min-width: $xl)
				flex-direction: row

			img
				width: 34px
				height: 38px
				@media (min-width: $xl)
					width: 70px
					height: 64px

			span
				line-height: 15px
				letter-spacing: -0.08em
				margin-top: 5px
				@media (min-width: $xl)
					margin-top: 0
					margin-left: 10px


		.center
			display: flex
			flex-direction: column
			margin-top: 15px
			@media (min-width: $xl)
				margin-top: 0

			span
				&:not(:last-child)
					margin-bottom: 5px

		.right
			display: flex
			align-items: center
			justify-content: center
			margin-top: 10px
			border-top: 2px solid $white
			padding-top: 10px
			@media (min-width: $xl)
				border-top: none
				padding-top: 0
				margin-top: 0
				justify-content: flex-end

			.created-by
				display: flex
				align-items: center
				img
					margin: 0 5px 5px

		.locale-switcher
			margin-left: auto
			@media (min-width: $xl)
				margin-left: 30px

			ul
				list-style: none
