.addPromoForm{
  height: 60vh;
  padding-top: 10px;
  .addInputGroup{
    margin-top: 10px;
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    div{
      label{
        margin-top: 10px;
        width: 100%;
        div{
          width: 100%;
          input{
            width: 100%;
          }
        }
      } 
    }
  }
}