@import 'src/styles/variables'

.text-xl
	font-size: 22px
	line-height: 24px

.text-lg
	font-size: 16px
	line-height: 30px

.text-md
	font-size: 16px
	line-height: 20px

.text-sm
	font-size: 14px
	line-height: 20px

.text-xs
	font-size: 12px
	line-height: 12px

.text-super
	font-size: 3em
	line-height: 62px

	@media (max-width: 475px)
		font-size: 2em

.text-super2
	font-size: 3em
	line-height: 62px

	@media (max-width: 475px)
		font-size: 1.8em

.text-labelinp
	font-family: 'Montserrat'
	font-style: normal
	font-weight: 400
	font-size: 12px
	line-height: 30px
	color: #FFFFFF

.color-primary
	color: $deep-black

.color-gray
	color: $gray-light

.color-danger
	color: $danger

.color-blue
	color: $blue

.color-blue-deep
	color: $blue-deep

.color-green
	color: $green

.color-red
	color: $red

.color-peach
	color: $peach

.color-white
	color: $white

.color-black
	color: $deep-black

.text-start
	text-align: left

.text-end
	text-align: right

.text-center
	text-align: center

.text-justify
	text-align: justify

.text-light
	font-weight: 300

.text-regular
	font-weight: 400

.text-medium
	font-weight: 500

.text-bold
	font-weight: 700

.text-overflow-ellipsis
	overflow: hidden
	white-space: nowrap
	text-overflow: ellipsis
	max-width: 100%

.text-overflow-fade
	position: relative
	display: block
	overflow: hidden
	white-space: nowrap
	max-width: 100%

	&::after
		position: absolute
		right: 0
		top: 0
		bottom: 0
		content: ''
		width: 2em
		background-image: linear-gradient(270deg, black 35%, transparent)

.text-transform-lowercase
	text-transform: lowercase

.text-transform-uppercase
	text-transform: uppercase

.text-decoration-underline
	text-decoration: underline

.text-decoration-line-through
	text-decoration: line-through
	text-decoration-color: $deep-black
	text-decoration-thickness: 2px

.required
	&:after
		content: "*"
		color: $red
