@import 'src/styles/variables'

.category, .product, .product-mobile, .breadcrumbs
	border: 2px solid #E5E6EB
	width: 100%
	height: 50px
	padding: 0 10px
	display: flex
	align-items: center
	position: relative

	&:not(:last-child)
		margin-bottom: 10px

	&__controls
		display: flex
		align-items: center
		margin-left: auto
		border-left: 2px solid $gray-background
		padding-left: 10px
		@media (min-width: $xl)
			border-left: none
			padding-left: 0

		i
			cursor: pointer
			@media (min-width: $xl)
				margin-left: 10px


.category
	&__title
		cursor: pointer


.product
	height: auto
	padding: 10px
	align-items: flex-start

	&__img
		width: 55px
		height: 55px
		margin-left: 10px

		img
			object-fit: contain
			width: 100%
			height: 100%

	&__title
		margin-left: 10px
		display: flex
		flex-direction: column

	&__switches
		margin-top: 5px
		display: flex
		flex-direction: column

	&__switch-item
		display: flex
		align-items: center

		span
			margin-left: 10px

		&:not(:last-child)
			margin-bottom: 10px

	&__controls
		align-self: center

		.product-price
			span + span
				margin-left: 10px

		.controls-group
			display: inline-flex
			margin-left: 10px


.product-mobile
	height: auto
	padding: 10px
	flex-direction: column
	align-items: flex-start
	position: relative

	&-top
		display: flex
		align-items: flex-start

	&-bottom
		display: flex
		margin-top: 10px
		width: 100%

	&__info
		margin-left: 10px
		margin-right: 40px

	&__img
		max-width: 80px
		width: 100%
		height: 80px
		display: flex
		align-items: center
		justify-content: center

		img
			width: auto
			height: 100%
			object-fit: cover
			object-position: center

	&__switches
		flex: 1

	&__switch
		display: flex
		justify-content: space-between

		&:not(:last-child)
			margin-bottom: 10px

	&__controls
		position: absolute
		top: 0
		right: 0
		width: 40px
		height: 33px
		justify-content: center
		padding: 0


.category-creator, .product-creator
	display: block

	hr
		background: $blue

	.title-with-faq
		display: flex
		align-items: center
		justify-content: space-between

	.result-item
		height: 33px
		border: 2px solid #E5E6EB
		padding: 10px
		display: flex
		align-items: center
		justify-content: space-between

		i
			padding-left: 5px
			border-left: 2px solid $gray-background
			@media (min-width: $xl)
				border-left: none

		&:not(:last-child)
			margin-bottom: 10px

	.photo-wrapper
		border: 2px solid $gray-light
		margin-top: 10px
		display: flex
		align-items: center
		justify-content: center
		flex-direction: column
		position: relative
		@media (min-width: $xl)
			border: none
			border: 2px solid #E5E6EB

		&.photo
			width: 100%
			height: 155px
			@media (min-width: $xl)
				height: 190px

		&.icon
			width: 110px
			height: 110px

			.controls
				width: 106px

				i
					width: 53px

		&.slider
			height: 152px

		&.banner-medium
			height: 110px

		&.banner-small
			height: 125px
			width: 125px

		&.banner-big
			height: 220px
			@media (min-width: $xl)
				height: 246px

		&.product-photo
			height: 155px
			position: relative
			@media (min-width: $xl)
				height: 195px

		.uploaded-photo
			object-fit: contain
			max-height: 100%
			max-width: 100%
			width: auto

		.close-control
			&:hover
				cursor: pointer

		.uploaded-photo-edit
			object-fit: contain
			max-height: 50%
			height: 50%
			width: auto


		.uploaded-photo-preview
			height: 100%
			width: auto
			object-fit: contain

		.uploaded-photo-input
			height: 100%
			width: auto
			position: absolute
			top: 0
			bottom: 0
			left: 0
			right: 0
			opacity: 0
			z-index: 1000


		.uploaded-icon-input
			position: absolute
			left: 0
			right: 0
			bottom: 0
			top: 0
			opacity: 0
			z-index: 1000


		.controls
			position: absolute
			right: 0
			bottom: 0
			border: 2px solid #E5E6EB
			background: white
			display: flex
			align-items: center
			justify-content: space-between
			width: 80px

			i
				width: 40px
				height: 35px
				background: $white
				display: flex
				align-items: center
				justify-content: center

				& + i
					border-left: 2px solid $gray-background

		.delete
			position: absolute
			top: 10px
			right: 10px

		label
			display: flex
			align-items: center
			justify-content: center
			flex-direction: column
			cursor: pointer
			height: 100%
			width: 100%

		span
			margin-top: 10px
			padding: 0 20px
			white-space: pre-line

	.modifications-list
		border-bottom: 2px solid $blue

	.decrease-margin-bottom
		margin-bottom: -10px


.control-product-add
	.found-product
		&:not(:last-child)
			margin-bottom: 10px

		padding: 10px
		background: $white
		border: 2px solid #E5E6EB
		display: flex
		@media (min-width: $xl)
			padding: 10px 20px

		&-mobile
			flex-direction: column

			&-top
				display: flex

			&-bottom
				margin-top: 10px
				display: flex

		&__photo
			max-width: 80px
			height: 80px
			width: 100%
			margin-right: 10px
			@media (min-width: $xl)
				max-width: 55px
				height: 55px
				margin-right: 15px

			img
				object-fit: contain
				width: 100%
				height: 100%

		&__check
			display: flex
			margin-right: 10px
			@media (min-width: $xl)
				align-items: center
				margin-left: auto
				margin-right: 0

	.product-config
		&__back
			display: flex
			align-items: center
			cursor: pointer

		&__product
			padding: 10px
			border: 2px solid #E5E6EB
			@media (min-width: $xl)
				padding: 10px 20px 0

			&:not(:last-child)
				margin-bottom: 10px

			.found-product
				justify-content: space-between
				box-shadow: none
				margin-bottom: 10px
				padding: 0

				&__check
					margin-right: 0

		&__info-mobile
			display: flex
			margin-top: 10px


.category-tree-mobile
	overflow: auto
	flex-wrap: nowrap !important
	padding: 5px 0
	margin: -5px


.category-section
	overflow: auto
	max-height: 100%

	&-wrapper
		border: 2px solid #E5E6EB
		height: 420px

	&__item-text
		flex: 1

	&__item
		padding: 13px 10px
		display: flex
		align-items: center
		transition: all .3s
		cursor: pointer
		@media (min-width: $xl)
			&:hover
				background: rgba(120, 194, 221, 0.5)

		&.active
			background: rgba(120, 194, 221, 0.5)

		.next-level-icon
			margin-left: auto


.breadcrumbs
	&__children
		margin-right: 20px


.checkbox-title
	display: flex
	align-items: center
	border: 2px solid #E5E6EB
	height: 33px
	padding: 10px

	p
		margin-left: 20px


.handler-language
	ul
		list-style: none
		display: flex

		li
			width: 63px
			height: 33px
			display: flex
			align-items: center
			justify-content: center
			background: $gray-light
			cursor: pointer
			transition: .3s

			&.active
				background: $blue

			& + li
				margin-left: 10px


// .newSearchForm
// 	display: flex
// 	justify-content: space-between
// 	margin-bottom: 12px
// 	width: 100%

// 	input
// 		width: 100%
