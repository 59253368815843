.ask-question-container
  height: 60vh
  
  div
    margin-bottom: 10px
    display: flex
    flex-direction: column
    span
      margin-bottom: 8px

  .askBtn
    display: flex
    flex-direction: row
    padding-left: 8px