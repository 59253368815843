.img-wrapper{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 34px;
	height: 24px;

	&__img{
		object-fit: cover;
	}
}
