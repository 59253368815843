@import 'variables'

h1
	font-size: 20px
	line-height: 22px

h2
	font-size: 16px
	line-height: 20px
	@media (min-width: $md)
		font-size: 18px


.text
	&-gray
		color: $gray-light

	&-uppercase
		text-transform: uppercase
