@import 'src/styles/variables'

.form-input-wrapper
	position: relative
	display: inline-block

	.errorMsg
		min-width: 142px
		padding: 8px 16px
		background: #78C2DD
		color: #FFFFFF
		border-radius: 2px
		animation: animateError 0.3s ease 0s 1 normal none
		position: absolute
		z-index: 2
		top: -21px
		font-family: 'Montserrat'
		font-style: normal
		font-weight: 400
		text-align: center
		white-space: nowrap
		font-size: 11px
		@media(max-width: 420px)
			font-size: 12px
			padding: 5px 7px

		&::after
			transform: rotate(180deg)
			content: ""
			position: absolute
			top: 28px
			border: 10px solid transparent
			border-bottom: 10px solid#78C2DD

	.contactError
		padding: 0
		font-size: 10px
		top: 32px
		text-align: start
		background: none
		color: red

		&::after
			border: transparent

	.mainStoreErrorTextarea
		padding: 0
		font-size: 10px
		text-align: start
		background: none
		color: red
		top: 168px
		&::after
			top: 28px
			border: transparent

	.contactErrorTextarea
		padding: 0
		font-size: 10px
		text-align: start
		background: none
		color: red
		top: 69px

		&::after
			border: transparent

	.mainStorePhoneError
		padding: 0
		font-size: 10px
		text-align: start
		background: none
		color: red
		top: 73px

		&::after
			// position: absolute
			// top: 28px
			border: transparent
			// border-bottom: 10px solid#78C2DD

	.requsitesError
		padding: 0
		font-size: 10px
		text-align: start
		background: none
		color: red
		top: 63px

		&::after
			border: transparent

	.contactErrorTextareaInfo
		padding: 0
		// position: static
		font-size: 10px
		text-align: start
		background: none
		color: red
		top: 168px

		&::after
			// top: 29px
			// left: 3px
			// content: ""
			// position: absolute
			top: 28px
			border: transparent
			// border-bottom: 10px solid#78C2DD

	.errorsPhoneRight
		left: 239px
		top: -29px
		@media(max-width: 1399.09px)
			left: 185px
		@media(max-width: 1199.09px)
			left: 0px

		&::after
			left: 12px
			@media(max-width: 420px)
				top: 22px

	.errorsFullName
		left: 239px
		top: -19px
		@media(max-width: 1399.09px)
			left: 185px
		@media(max-width: 1199.09px)
			left: 0px

		&::after
			left: 12px
			@media(max-width: 420px)
				top: 22px

	.errorsStoreTextAreaRight
		left: 236px
		top: -29px
		@media(max-width: 1399.09px)
			left: 185px
		@media(max-width: 1199.09px)
			left: 0px

		&::after
			left: 12px
			@media(max-width: 420px)
				top: 22px

	.errorOrganizationLeft
		top: -31px
		right: 239px
		@media(max-width: 1399.09px)
			right: 180px
			// right: auto
			// left: 0
		@media(max-width: 1199.09px)
			right: auto
			left: 0
		&::after
			right: 12px
			@media(max-width: 1199.09px)
				right: auto
				left: 0
			@media(max-width: 420px)
				top: 23px

	.errorsStoreNameLeft
		top: -29px
		left: -206px
		@media(max-width: 1199.09px)
			left: 0

		&::after
			right: 10px
			@media(max-width: 1199.09px)
				right: auto
				left: 0
				@media(max-width: 420px)
					top: 22px

	.errorsDomainLeft
		top: -29px
		left: -181px
		@media(max-width: 1199.09px)
			left: 0

		&::after
			right: 11px
			@media(max-width: 1199.09px)
				right: auto
				left: 0
				@media(max-width: 420px)
					top: 22px

	.errorMsgLeft
		top: 0
		right: 0

		&::after
			// top: 28px
			left: 7px
	.errorMsgRight
		top: -26px
		left: 537px
		@media (max-width: 992px)
			left: 0

		&::after
			// top: 0
			left: 8px
			@media(max-width: 420px)
				top: 22px

	.errorsCheckBoxLeft
		top: -33px
		right: -9px
		@media(max-width: 1226px)
			left: -1px
			right: auto
			@media(max-width: 420px)
				top: -27px

		&::after
			right: 7px
			top: 28px
			@media(max-width: 1226px)
				left: 0
				right: auto
				@media(max-width: 420px)
					top: 22px

	.errorsCheckBoxLeft2
		top: -29px
		right: -9px
		@media(max-width: 1226px)
			left: -1px
			right: auto
			@media(max-width: 420px)
				top: -27px

		&::after
			right: 7px
			top: 28px
			@media(max-width: 1226px)
				left: 0
				right: auto
				@media(max-width: 420px)
					top: 22px

	.errorsTimeLeft
		top: -29px
		left: -111px
		@media(max-width: 1199.09px)
			left: 0

		&::after
			right: 11px
			@media(max-width: 1199.09px)
				right: auto
				left: 0
				@media(max-width: 420px)
					top: 22px

	.errorsTimeRight
		top: -29px
		left: 81px
		@media(max-width: 1399.09px)
			left: 60px
			@media(max-width: 1199.09px)
				left: 10px
		@media (max-width: 992px)
			left: 0

		&::after
			// top: 0
			left: 8px
			@media(max-width: 420px)
				top: 22px

	.label
		display: block
		margin-bottom: 10px
		color: black

	&.error
		.input, &.select > div
			border-color: $danger
			box-shadow: $shadow-danger

		.label
			color: $danger

	&.isFluid
		width: 100%

		.input-container,
		.input
			width: 100%

	&.search
		.input
			padding: 5px 10px

	&.checkbox
		cursor: pointer
		display: flex

.input-container
	position: relative
	display: inline-flex

	.prefix
		position: absolute
		left: 0
		top: 0
		bottom: 0
		display: flex
		align-items: center
		justify-content: center
		padding-left: 10px
		transition: color 0.3s ease

		i
			color: $gray

	.suffix
		position: absolute
		right: 0
		top: 0
		bottom: 0
		display: -moz-box
		display: flex
		align-items: center
		justify-content: center
		padding-right: 10px
		transition: all 0.3s ease

		i
			color: $gray

		span + i
			margin-left: 5px

	.toggle-password
		cursor: pointer
		display: inline-flex

		i
			&::after
				background: $gray

.input
	background: #F2F3F5
	border: 1px solid #E5E6EB
	line-height: 15px
	transition: all 0.3s ease
	appearance: none
	outline: none
	padding: 10px
	font-size: 12px
	font-family: $font-primary
	font-weight: 600
	resize: none
	height: 33px
	@media (min-width: $md)
		font-size: 14px

	&-group
		display: flex

		.form-input-wrapper
			flex: 1 1 auto

	&-group-item
		display: flex
		align-items: center
		text-align: center
		white-space: nowrap
		border: 2px solid #E5E6EB

	&.hasPrefix
		padding-left: 38px !important

	&.hasSuffix
		padding-right: 40px

	&.password
		padding-right: 40px

		&.hasSuffix
			padding-right: 70px

	&.sm
		height: 33px

	&.md
		height: 70px

	&.lg
		height: 132px

	&.xl
		height: 140px

	&::placeholder
		color: $gray-light

	&:focus
		& ~ .suffix i,
		& ~ .prefix i
			color: $deep-black

			&::after
				background: $deep-black

	&:focus,
	&:hover
		// box-shadow: $shadow-secondary

.input2
	height: 48px
	width: 100%
	background: #FFFFFF
	box-shadow: 0px 3.42735px 51.4103px rgba(20, 0, 79, 0.04)
	border-radius: 10px
	font-family: 'Montserrat'
	font-style: normal
	font-weight: 400
	font-size: 18px
	line-height: 30px
	outline: none
	border: none
	padding-left: 20px

.textarea2
	height: 48px
	width: 100%
	background: #FFFFFF
	box-shadow: 0px 3.42735px 51.4103px rgba(20, 0, 79, 0.04)
	border-radius: 10px
	font-family: 'Montserrat'
	font-style: normal
	font-weight: 400
	font-size: 18px
	line-height: 30px
	outline: none
	border: none
	padding-left: 20px
	padding-top: 10px
	resize: none
.checkbox,
.radio
	position: relative

	.input
		position: absolute
		opacity: 0
		height: 0
		width: 0

	> .label
		margin-bottom: 0
		float: right

	> .input-container
		vertical-align: middle

	.checkmark
		position: relative
		background: $white
		transition: background 0.3s ease

.checkbox
	.checkmark
		border: 2px solid $gray
		height: 18px
		width: 18px

		&::before
			position: absolute
			content: ''
			display: block
			top: 50%
			left: 50%
			width: 4px
			height: 9px
			border-style: solid
			border-color: $deep-black
			border-width: 0 2px 2px 0
			transform: translate(-50%, -60%) rotate(45deg)
			opacity: 0
			transition: opacity 0.3s ease

		&.checkbox-secondary
			border: none
			background: $gray-background

			&::before
				border-width: 0 1px 1px 0

	.input
		&:checked ~ .checkmark
			&::before
				opacity: 1

.radio
	.checkmark
		border-radius: 50%
		border: 2px solid $gray
		overflow: hidden
		display: flex
		align-items: center
		justify-content: center
		height: 25px
		width: 25px
		margin-right: 5px

		&::before
			content: ''
			display: block
			background: $blue
			width: 11px
			height: 11px
			border: 2px solid #E5E6EB
			border-radius: 50%
			opacity: 0
			transition: opacity 0.3s ease

	.input
		&:checked ~ .checkmark
			//border-color: $deep-black

			&::before
				opacity: 1

.color-blue
	color: $blue

.size-sm

.size-md
	width: 245px

@keyframes animateError
	0%
		opacity: 0

	100%
		opacity: 1
