.promocode-list {
  margin-top: 16px;
  margin-bottom: 16px;
  width: 100%;

  .promocode-item {
    width: 100%;
    border-bottom: 1px solid #006296;

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 16px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 30px;
    cursor: pointer;
  }


  .controls-group {
    height: 100%;
    display: flex;


    div {
      margin-left: 10px;
    }

  }

}