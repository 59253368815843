.imgContainer
  position: relative

  .deleteEdit2
    display: flex
    justify-content: space-evenly
    width: 60px
    height: 30px

    top: 361px
    background: #FFFFFF
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2)
    border-radius: 0px 5px
    position: absolute
    top: 8.5px
    left: 300px

  .deleteEdit4
    display: flex
    justify-content: space-evenly
    width: 60px
    height: 30px

    top: 361px
    background: #FFFFFF
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2)
    border-radius: 0px 5px
    position: absolute
    top: 8.5px
    right: 25px

  .deleteEdit3
    display: flex
    justify-content: space-evenly
    width: 60px
    height: 30px

    top: 361px
    background: #FFFFFF
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2)
    border-radius: 0px 5px
    position: absolute
    top: 5px
    left: 189px

  .deleteEdit
    display: flex
    justify-content: space-evenly
    width: 60px
    height: 30px

    top: 361px
    background: #FFFFFF
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2)
    border-radius: 0px 5px
    position: absolute
    top: 5px
    left: 320px

  .banner:hover
    filter: drop-shadow(0px 0px 10px #78C2DD)

.bannerLib:hover
  filter: drop-shadow(0px 0px 10px #78C2DD)

.editor


.simpleCanvas>div
  height: 350px !important
.simpleCanvas>div>canvas
  height: 350px !important
