.navli{
  display: flex;
  justify-content: center;
  cursor: pointer;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  padding: 8px 12px;
  margin-right: 20px;
}

.main {
	& + footer {
		margin-top: 0 !important;
	}
}

.section {
	@media screen and (min-width: 1200px) {
    min-height: 960px;
  }
}
