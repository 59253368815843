@import 'src/styles/variables'

.locale-switcher
	position: relative
	width: 66px
	height: 33px
	box-sizing: border-box
	background: #F4F4F4
	box-shadow: inset 0px 0px 4px rgba(131, 129, 129, 0.25)
	border-radius: 300px
	color: black
	display: flex
	justify-content: space-between
	align-items: center
	padding-left: 10px
	padding-right: 10px
	cursor: pointer
	font-family: 'Montserrat'
	font-style: normal
	font-weight: 400
	font-size: 12px
	line-height: 15px

	&__button
		position: absolute
		cursor: pointer
		top: 6%
		left: 3%
		width: 29px
		height: 29px
		background: linear-gradient(107.33deg, #0094E7 20.64%, #31ACD5 90.51%)
		box-shadow: 0px 0px 4px rgba(8, 31, 67, 0.63)
		transition: .3s ease all, left .3s cubic-bezier(0.18, 0.89, 0.35, 1.15)
		border-radius: 50%
		z-index: 1000
