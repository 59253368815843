@import 'src/styles/variables'

.custom-tooltip
	background: $white
	width: 140px
	height: 90px
	border-radius: 15px
	padding: 15px
	display: flex
	flex-direction: column
	box-shadow: 0px 1px 8px 1px rgba(34, 60, 80, 0.11)
	
	&:focus-visible
		border:none
		outline:none 
