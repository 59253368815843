@import 'src/styles/variables'

.icon-wrapper
	display: flex
	align-items: center
	justify-content: center

	&.position-relative
		position: relative


.primary
	color: $deep-black

.white
	color: $white

.peach
	color: $peach

.gray
	color: $gray

.gray-light
	color: $gray-light

.green
	color: $green

.red
	color: $red

.blue
	color: $blue

.blue-deep
	color: $blue-deep


.xl
	font-size: 38px

.lg
	font-size: 30px

.md
	font-size: 24px

.sm
	font-size: 20px

.xs
	font-size: 10px


.turn-off
	position: relative

	&::after
		content: ''
		position: absolute
		background: $deep-black
		height: 1px
		transform: rotate(45deg)


	&.md
		&::after
			width: 25px
			top: 11px
			right: -1px


.hover
	cursor: pointer
	transition: .2s

	&.hover-gray
		&:hover
			color: $gray


.pointer
	cursor: pointer


.custom-title
	position: absolute
	width: 500px
	background-color: $white
	color: $deep-black
	font-size: 12px
	font-family: $font-primary
	font-weight: 700
	line-height: 15px
	padding: 20px
	top: 50%
	transform: translateY(-50%)
	border: 2px solid #E5E6EB
	z-index: 9999
	border-radius: 5px

	&:after
		content: attr(data-title)

	&:before
		content: url('/assets/custom-title-triangle.png')
		//content: ''
		//border-top: 12px solid transparent
		//border-right: 30px solid #ffffff
		//border-bottom: 12px solid transparent
		position: absolute
		filter: drop-shadow(3px 0px 2px rgba(0, 0, 0, 0.1))
		top: 50%
		transform: translateY(-50%)

	&-left
		right: 80px

		&:before
			right: -25px

	&-right
		left: 80px

		&:before
			left: -25px
			transform: translateY(-50%) rotate(180deg)
