.canvas {
	width: 100%;
	height: 100%;

	& > div {
		height: 100% !important;
		width: 100% !important;

		& > canvas {
			height: 100% !important;
			width: 100% !important;
		}
	}
}
