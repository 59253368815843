@import 'src/styles/variables'

.seo-settings
	margin-bottom: -20px

	@media (min-width: $xl)
		padding: 20px 20px 0
		background: $white
		border: 2px solid #E5E6EB
		margin-bottom: 0

	.title-with-faq
		display: flex
		align-items: center

		i
			margin-left: 10px


.favicon-settings
	&-wrapper
		@media (min-width: $xl)
			position: relative
			padding: 20px
			background: $white
			border: 2px solid #E5E6EB
			height: 160px
		@media (min-width: $xxxl)
			height: 135px

	&__upload
		display: flex
		flex-direction: column-reverse
		@media (min-width: $xl)
			flex-direction: row

		label
			width: 64px
			height: 64px
			border: 2px dashed #C0C0C0
			display: flex
			align-items: center
			justify-content: center
			margin-top: 10px
			@media (min-width: $xl)
				margin-top: 0

			img
				width: 100%
				height: 100%
				object-fit: contain

		.example-img
			flex: 1
			@media (min-width: $xl)
				margin-left: 20px
				margin-right: 40px

	&__faq
		position: absolute
		right: 20px
		top: 20px


.domain-settings
	&-wrapper
		@media (min-width: $xl)
			position: relative
			padding: 20px
			background: $white
			border: 2px solid #E5E6EB
			display: flex
			height: 160px
		@media (min-width: $xxxl)
			height: 135px

	&__content
		display: flex
		flex-direction: column
		@media (min-width: $xl)
			margin-left: 20px

		button
			margin-right: auto

	&__faq
		position: absolute
		right: 20px
		top: 20px


.img-wrapper
	width: 100%
	height: 50px
	@media (min-width: $xl)
		height: 64px

	img
		width: 100%
		height: 100%
		object-fit: cover
		object-position: left


.control-domain-edit
	.default-domain
		width: 213px
		height: 29px
		background: linear-gradient(107.33deg, #0094E7 20.64%, #31ACD5 90.51%)
		color: $white
		display: flex
		align-items: center
		justify-content: center
		font-size: 15px

	.or
		margin: 20px 0
		display: flex
		align-items: center
		justify-content: center
		position: relative

		&:before
			content: ''
			position: absolute
			height: 2px
			width: 100%
			background: $gray-background

		&__circle
			width: 30px
			height: 30px
			background: $blue
			border-radius: 50%
			position: relative
			display: flex
			align-items: center
			justify-content: center


	.instruction
		margin-top: 10px

		p
			margin-bottom: 10px
