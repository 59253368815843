@import 'src/styles/variables'

.link-wrapper
	width: 60px
	height: 60px
	display: flex
	align-items: center
	justify-content: center
	border-radius: 50%
	background: $blue
	cursor: pointer
	transition: all .3s
	&:not(:last-child)
		margin-right: 20px!important

	&:hover, &.active
		background: $red
