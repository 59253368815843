@import 'src/styles/variables'

.search-form-wrapper
	position: relative

	&.active
		z-index: 99

		.search-result-wrapper
			display: block

	.search-form
		background: #F2F3F5
		border: 1px solid #E5E6EB
		border-radius: 5px
		& > div
			width: 100%

	.search-result-wrapper
		display: none
		position: absolute
		left: 0
		right: 0
		margin-top: 10px
		background: $white
		border: 2px solid #E5E6EB
		z-index: 10

		.search-result
			//max-height: calc(100vh - 350px)
			max-height: 26px * 5
			overflow-y: auto
			padding: 10px
			display: flex
			flex-direction: column

			&__item
				cursor: pointer
				padding-left: 5px
				padding-right: 5px

				span
					transition: .3s

				&:not(:last-child)
					border-bottom: 1px solid #00000018
					padding-bottom: 5px

				&:not(:first-child)
					padding-top: 5px

				&:hover
					span
						color: $gray

.search-form
	&-group
		display: flex

		.search-form-wrapper
			width: 100%

	&-group-item
		display: flex
		align-items: center
		text-align: center
		white-space: nowrap
