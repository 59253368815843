@import 'src/styles/variables'

.loading
	display: inline-block
	position: relative

	&-wrapper
		position: fixed
		height: 100vh
		width: 100vw
		top: 50%
		left: 50%
		transform: translate(-50%, -50%)
		display: flex
		align-items: center
		justify-content: center
		background: #6a6a6a4a
		z-index: 9
		transition: .2s

	div
		box-sizing: border-box
		display: block
		position: absolute
		width: 50px
		height: 50px
		margin: 8px
		border: 5px solid
		border-radius: 50%
		animation: loading-ring 1.5s cubic-bezier(0.5, 0, 0.5, 1) infinite
		border-color: #68d8ff transparent transparent transparent

		&:nth-child(1)
			animation-delay: -0.45s

		&:nth-child(2)
			animation-delay: -0.3s

		&:nth-child(3)
			animation-delay: -0.15s


@keyframes loading-ring
	0%
		transform: rotate(0deg)

	100%
		transform: rotate(360deg)
