.form {
	display: flex;
	flex-direction: column;
	justify-content: center;

	&LogoContainer {
		width: 100%;

		&__logo {
			display: block;
			height: 50px;
			aspect-ratio: 211 / 50;
			margin-bottom: 16px;
		}
	}

	&SeparatorContainer {
		position: relative;
		width: 100%;
		display: flex;
		justify-content: center;
		margin-top: 31px;
		margin-bottom: 20px;

		&__or {
			z-index: 1;
			width: 30px;
			height: 30px;
			border-radius: 50%;
			background-color: #006296;
			display: flex;
			align-items: center;
			justify-content: center;

			color: #FFF;
			font-family: Montserrat;
			font-size: 12px;
			font-style: normal;
			font-weight: 700;
			line-height: 150%; /* 18px */
		}

		&:before {
			content: "";
			background: #F6F6F6;
			width: 100%;
			height: 2px;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}
	}
}
