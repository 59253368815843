@import 'src/styles/variables'

.control-menu
	overflow: auto
	position: relative
	height: 100%
	min-height: 66vh
	@media (min-width: $xl)
		padding: 0 70px 20px

	&__header
		height: 80px
		display: flex
		align-items: center
		justify-content: space-between
		position: relative
		padding: 10px
		@media (min-width: $xl)
			padding: 0
			height: 70px
			border-bottom: 2px solid $blue
			box-shadow: none

		.close-button
			position: absolute

			&.right
				right: 10px
				@media (min-width: $xl)
					right: 0

				& ~ .header-content-wrapper
					margin-right: 30px

			&.left
				left: 10px
				@media (min-width: $xl)
					left: 0

				& ~ .header-content-wrapper
					margin-left: 30px

		.header-content
			display: flex
			align-items: center

			&-wrapper
				width: 100%

			&__icon
				max-width: 34px
				width: 100%
				height: 34px
				background: #006296
				border-radius: 50%
				display: flex
				align-items: center
				justify-content: center
				margin-right: 5px
				@media (min-width: $xl)
					max-width: 60px
					height: 60px

			&__title
				width: 100%
				color: #006296
				@media (min-width: $xl)
					width: auto


	&__content
		margin-top: 10px
		padding: 10px 10px 30px
		@media (min-width: $xl)
			padding: 0
