@import 'src/styles/variables'

.disclosure
	position: relative
	display: flex

	&-menu
		opacity: 0
		position: absolute
		right: 0
		z-index: 9
		text-overflow: ellipsis
		overflow: hidden
		white-space: nowrap
		text-align: end
		pointer-events: none
		border: 2px solid #E5E6EB
		background: $white

		& > *
			&:not(:last-child)
				margin-bottom: 5px

		&.active
			opacity: 1
			pointer-events: auto
			top: 30px
			transition: .2s ease-in all
			padding: 10px
