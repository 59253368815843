@import 'src/styles/variables'

.filter
	background: $white
	border: 1px solid #E5E6EB
	padding: 10px

	&.mobile
		box-shadow: none
		padding: 0

	.filter-item
		& > span + div
			margin-top: 10px

		& + .filter-item
			margin-top: 10px


.header-mobile
	align-items: center
	display: flex

	&__addition
		margin-left: auto

	&__title
		margin-left: calc(50% - 90px)
		max-width: 180px
		width: 100%
		margin-right: 20px
