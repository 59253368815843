.infoPromoBlock{
  height: 60vh;
  padding-top: 10px;
  .infoPromoGroup{
    margin-top: 12px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;

  }
}