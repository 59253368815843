.labelInp
  font-family: 'Montserrat'
  font-style: normal
  font-weight: 400
  font-size: 12px
  line-height: 30px
  color: #FFFFFF

.btnBack
  min-width: 87px
  padding: 9px 18px
  background: #51B7E9
  border-radius: 7px
  font-family: 'Montserrat'
  font-style: normal
  font-weight: 500
  font-size: 16px
  line-height: 23px
  color: #FFFFFF
  cursor: pointer
  transition: 0.5s
  box-sizing: border-box

  &:hover
    background: #1F9DDB
    border: 1px solid white

.btnNewAuth
  min-width: 208px
  padding: 9px
  background: #51B7E9
  border-radius: 7px
  font-family: 'Montserrat'
  font-style: normal
  font-weight: 500
  font-size: 16px
  line-height: 23px
  color: #FFFFFF
  cursor: pointer
  transition: 0.5s
  box-sizing: border-box

  &:hover
    background: #1F9DDB
    border: 1px solid white

.btnNewAuth2
  min-width: 208px
  padding: 9px
  background: #51B7E9
  border-radius: 7px
  font-family: 'Montserrat'
  font-style: normal
  font-weight: 500
  font-size: 16px
  line-height: 23px
  color: #FFFFFF
  cursor: pointer
  transition: 0.5s
  box-sizing: border-box
  margin-top: 20px
  margin-left: 25%

  @media (max-width: 475px)
    margin-left: 12%

  &:hover
    background: #1F9DDB
    border: 1px solid white
