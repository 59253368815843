@import 'src/styles/variables'

.order-table
	margin-top: 10px

.total
	&.orders
		margin-top: 10px

		display: flex

	span
		margin-left: 10px

	@media (min-width: $sm)
		justify-content: flex-start
	@media (min-width: $xl)
		font-size: 18px

.clients-info
	background: $white
	@media (min-width: $xl)
		border: 2px solid #E5E6EB
		padding: 10px

	ul
		list-style: none

		li
			&:not(:last-child)
				margin-bottom: 10px

	&__title
		display: none
		height: 23px
		align-items: center
		color: $blue
		@media (min-width: $xl)
			display: flex

	&__item
		border: 2px solid #E5E6EB
		padding: 10px
		height: 33px
		display: flex
		align-items: center
		justify-content: space-between

.clInfo
	width: 100%
	height: 104px
	background: #FFFFFF
	border: 1px solid #E5E6EB
	display: flex
	flex-direction: column
	justify-content: center
	align-items: center
	margin-bottom: 10px

	.clInfoNum
		font-family: 'Montserrat'
		font-style: normal
		font-weight: 400
		font-size: 32px
		line-height: 150%
		text-align: center
		color: #464646

	.clInfoTitle
		font-family: 'Montserrat'
		font-style: normal
		font-weight: 700
		font-size: 14px
		line-height: 150%
		text-align: center
		font-feature-settings: 'liga' off
		color: #C0C0C0

.clSel
	width: 100%
	margin-bottom: 10px
	label
		width: 100%
		div
			width: 100%

.clOpen
	width: 100%
	margin-bottom: 10px
	border: 1px solid #E5E6EB
	background: white
	display: flex
	justify-content: space-between
	padding: 10px
	cursor: pointer
	.clOpenTitle
		font-family: 'Montserrat'
		font-style: normal
		font-weight: 700
		font-size: 16px
		line-height: 20px
		display: flex
		align-items: center
		color: #006296
		
