.yAxis
	.recharts-text
		font-size: 10px

.xAxis
	.recharts-text
		font-size: 12px
		line-height: 18px


.recharts-pie
	text
		fill: black
		font-size: 18px
