@import 'src/styles/variables'

.button
	height: 33px
	font-family: $font-primary
	font-size: 15px
	font-weight: 700
	line-height: 18px
	cursor: pointer
	transition: background-color 200ms linear
	position: relative
	padding: 5px
	display: inline-flex
	align-items: center
	justify-content: center
	text-align: center
	align-self: center
	border: none
	border-radius: 5px
	outline: 0
	color: $white
	@media (min-width: $xl)
		padding: 5px 10px

	&:disabled
		cursor: default
		box-shadow: none
		background: $gray-light

		&:hover
			background: $gray-light


.primary
	background: linear-gradient(107.33deg, #0094E7 20.64%, #31ACD5 90.51%)

	// &:hover
	// 	background: linear-gradient(107.33deg, #0094E7 20.64%, #1b586d 90.51%)

.secondary
	background: $green-light

	&:hover
		background: $green

.tertiary
	background: #2771C4

	&:hover
		background: #134e93

.header-nav
	background: $gray-background
	font-size: 12px
	height: 31px
	color: $gray-light

	&.active
		background: $white
		color: $deep-black

	@media (min-width: $xl)
		font-size: 18px
		border-radius: 0px
		background: white
		padding-bottom: 8px

		border-bottom: 2px solid #006296
		height: 34px
		color: #006296

		&.active, &:hover
			color: #EB2316
			border-bottom: 2px solid #EB2316


.sm
	width: 140px

.md
	min-width: 100px
	@media (min-width: $xl)
		width: 170px

.lg
	width: 245px

.isFluid
	width: 100%


.color-gray
	background: $gray-light

	&:hover
		background: $gray

.cancel
	color: #0094E7
	border: 2px solid rgba(0, 148, 231, 1)


.button-group
	display: flex
	margin-left: -5px
	margin-right: -5px
	@media (min-width: $xl)
		margin-left: -10px
		margin-right: -10px

	.button
		margin-left: 5px
		margin-right: 5px
		@media (min-width: $xl)
			margin-left: 10px
			margin-right: 10px

	&.column
		flex-direction: column
		margin-left: 0
		margin-right: 0

		button
			&:not(:last-child)
				margin-bottom: 10px
