@import 'src/styles/variables'

.custom-col
	flex: 0 0 100%
	max-width: 100%
	padding-left: 10px
	padding-right: 10px
	@media (min-width: $xs)
		flex: 0 0 356px
		max-width: 356px
	@media (min-width: $xl)
		padding-top: 60px


.rate
	background: $white
	padding: 20px 41px 25px
	display: flex
	flex-direction: column
	align-items: center
	text-align: center
	height: 100%
	border-radius: 37px
	border: 2px solid #E5E6EB
	justify-content: space-between
	transition: all .3s linear
	min-height: 415px

	&.active, &:hover
		background: #1592ec
		box-shadow: -100px 0px 120px 0px rgb(232 245 249 / 30%) inset
		@media (min-width: $xl)
			transform: translateY(-50px)

		.rate__title,
		.rate__number,
		.rate__description,
		.rate__cost
			color: $white

		.rate__title
			border-bottom: 1px solid $white

		.rate__description
			opacity: 1

		.rate__button
			border: 2px solid $white

			span
				color: $white

	&__title
		margin-bottom: 10px
		padding-bottom: 10px
		border-bottom: 1px solid #030028
		width: 100%
		font-size: 24px
		line-height: 29px
		transition: all .3s linear

	&__number
		font-size: 16px
		line-height: 23px
		transition: all .3s linear

	&__description
		margin-top: 10px
		font-size: 16px
		line-height: 23px
		font-weight: 500
		opacity: 0.7
		transition: all .3s linear

	&__cost
		margin-top: 12px
		color: $blue

		p
			font-size: 60px
			line-height: 78px
			white-space: pre
			transition: all .5s linear

			span
				font-size: 22px
				line-height: 29px

	&__button
		margin-top: 22px
		height: 48px
		width: 200px
		border: 2px solid $blue
		border-radius: 10px
		display: flex
		align-items: center
		justify-content: center
		margin-left: auto
		margin-right: auto
		cursor: pointer
		transition: all .3s linear

		span
			color: $blue
			font-size: 18px
			line-height: 24px
			transition: all .3s linear

.memory-chart
	display: flex
	flex-direction: column
	@media (min-width: $xxl)
		margin-left: -75px

	&__taken, &__free
		display: flex
		align-items: center

		& > div
			width: 24px
			height: 16px
			margin-right: 10px

		span + span
			margin-left: 4px

	&__taken
		& > div
			background: $red

	&__free
		margin-top: 16px

		& > div
			background: $green-light
