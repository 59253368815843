@import 'src/styles/variables'

.header
	position: fixed
	width: 100vw
	top: 0
	left: 0
	background: $white
	border-bottom: 2px solid #E5E6EB
	z-index: 9
	@media(min-width: $xl)
		height: 70px
		left: -4px

	ul, ol
		list-style: none

	&-wrapper
		display: flex
		align-items: center
		padding: 10px 0
		height: 100%
		flex-direction: column
		position: relative

		@media(min-width: $xl)
			flex-direction: row
			padding: 15px 0

	&-top
		position: relative
		display: flex
		flex-direction: column
		width: 100%
		align-items: center
		@media(min-width: $xl)
			width: 350px
			flex-direction: row
			justify-content: flex-start


	&__logo
		display: flex
		align-items: center
		justify-content: center
		margin: 1px 0 2px
		height: 34px
		width: 34px
		background: $red
		border-radius: 50%
		@media(min-width: $xl)
			height: 60px
			width: 60px
			margin: 0

	&__title
		@media(min-width: $xl)
			margin-left: 10px

	&__burger-menu
		position: absolute
		left: 0
		top: 50%
		transform: translate(0, -50%)

	&__nav
		overflow-x: auto
		width: 100%

		ul
			overflow-x: auto
			overflow-y: hidden
			display: flex
			padding: 5px
			background: $gray-background
			margin-top: 10px
			@media(min-width: $xl)
				margin-top: 0
				background: none

			li
				@media(min-width: $xl)
					&:not(:last-child)
						margin-right: 26px

	&__options
		margin-left: 20px
		position: absolute
		right: 10px
		top: 50%
		transform: translateY(-50%)


.mobile-menu
	overflow-y: auto
	margin-top: -10px
	
	&::-webkit-scrollbar
		width: 0
		height: 0

	&__item
		padding: 10px 0

		&:not(:last-child)
			border-bottom: 2px solid #F6F6F6

		&:first-child
			padding-top: 0

		.link
			display: flex
			align-items: center
			justify-content: flex-start
			transition: .3s

			span
				margin-left: 10px
				font-size: 18px

			&.active
				i, span
					color: $gray-light


.header-burger-menu
	display: flex
	align-items: center
	justify-content: center

	&__logo
		margin-right: 30px
		width: 45px
		height: 45px

		img
			width: 100%
			height: 100%
			object-fit: contain

		.default-logo
			background: $blue
			border-radius: 5px
			height: 100%
			display: flex
			align-items: center
			justify-content: center
