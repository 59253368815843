@import 'src/styles/variables';

.rc-tree-select {
	font-size: 12px;
	position: relative;
	display: inline-block;
	cursor: pointer;
	max-width: 100%;
	min-width: 205px;
	border: 1px solid #E5E6EB;
	height: 33px;

	@media (min-width: $xl) {
		font-size: 14px;
	}

	&.error {
		border-color: $danger;
		box-shadow: $shadow-danger;
	}

	&--fluid {
		width: 100%;
	}

	&-selector {
		border: none !important;
		padding: unset !important;
	}

	&-disabled {
		cursor: default;

		input {
			cursor: default;
		}

		.rc-tree-select-selector {
			opacity: 0.3;
		}
	}

	&-selection {
		&-placeholder {
			opacity: 0.4;
			pointer-events: none;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}

		&-search-input {
			appearance: none;
		}

		&-search-input::-webkit-search-cancel-button {
			display: none;
			appearance: none;
		}

		&__choice-zoom {
			transition: all 0.3s;
		}

		&__choice-zoom-appear {
			opacity: 0;
			transform: scale(0.5);

			&.rc-tree-select-selection__choice-zoom-appear-active {
				opacity: 1;
				transform: scale(1);
			}
		}

		&__choice-zoom-leave {
			opacity: 1;
			transform: scale(1);

			&.rc-tree-select-selection__choice-zoom-leave-active {
				opacity: 0;
				transform: scale(0.5);
			}
		}
	}

	&-show-arrow {
		.rc-tree-select-selector {
			padding-right: 25px;
		}

		.rc-tree-select-arrow {
			pointer-events: none;
			position: absolute;
			right: 0;
			padding: 5px 10px 5px 5px;
			top: 0;
			bottom: 0;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.rc-tree-select-arrow-icon {
			display: flex;

			&::after {
				font-family: "icomoon" !important;
				content: '\e911';
				display: inline-block;
				border: unset;
				border-top-color: $gray;
				font-size: 16px;
				width: unset;
				height: unset;
				transform: unset;
			}
		}

		&.rc-tree-select-loading .rc-tree-select-arrow-icon::after {
			font-size: 0;
			box-sizing: border-box;
			width: 12px;
			height: 12px;
			border-radius: 100%;
			border: 2px solid $gray;
			border-top-color: transparent;
			border-bottom-color: transparent;
			transform: none;
			margin-top: 4px;
			animation: rcSelectLoadingIcon 0.5s infinite;
		}

		&.rc-tree-select-multiple .rc-tree-select-selector {
			padding-right: 20px;
		}
	}

	&.rc-tree-select-show-search {
		.rc-tree-select-selection-search {
			.rc-tree-select-selection-search-input {
				cursor: default;
			}
		}
	}

	&-single {
		.rc-tree-select-selector {
			display: flex;
			position: relative;
			background: $white;
			overflow: hidden;
		}

		.rc-tree-select-selection-search {
			width: 100%;
		}

		.rc-tree-select-selection-search-input {
			width: 100%;
			cursor: pointer;
		}

		.rc-tree-select-selection-item {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			padding: 6px 10px;
			pointer-events: none;
		}

		.rc-tree-select-selection-placeholder {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			pointer-events: none;
			padding: 5px 10px;
		}

		&:not(.rc-tree-select-customize-input) .rc-tree-select-selector {
			.rc-tree-select-selection-search-input {
				border: none;
				outline: none;
				width: 100%;
				height: 30px;
			}
		}
	}

	&-allow-clear {
		.rc-tree-select-selector {
			padding-right: 20px;
		}

		.rc-tree-select-clear {
			position: absolute;
			padding: 5px;
			right: 0;
			top: 0;
			bottom: 0;
			display: flex;
			align-items: center;
		}

		&.rc-tree-select-show-arrow {
			.rc-tree-select-selector {
				padding-right: 40px;
			}

			.rc-tree-select-clear {
				right: 20px;
			}
		}
	}

	&-item {
		font-size: 14px;
		padding: 4px 10px;
		cursor: pointer;

		&-group {
			color: $gray;
			font-weight: bold;
			font-size: 80%;
		}

		&-option {
			position: relative;
			color: $gray;

			&-selected {
				color: $blue;
			}

			.rc-tree-select-item-option-state {
				position: absolute;
				top: 0;
				bottom: 0;
				right: 0;
				padding: 5px;
				pointer-events: none;
				display: none;
			}
		}

		&-option-grouped {
			padding-left: 24px;
		}

		&-option-active {
			background: rgba(213, 213, 213, 0.17);
		}

		&-option-disabled {
			color: $gray;
		}

		&-empty {
			text-align: center;
			color: $gray;
		}
	}

	&-dropdown {
		min-height: 45px;
		position: absolute;
		background: $white;
		overflow: hidden;
		border: 2px solid #E5E6EB;
		z-index: 999999;

		.rc-tree-select-tree {
			&-switcher {
				background: none !important;
			}

			&-treenode {
				display: flex;
				align-items: center;

				&:hover {
					background-color: rgba(213, 213, 213, 0.17);
				}
			}

			&-iconEle {
				display: none !important;
			}

			&-treenode-selected {
				color: $blue;

				.rc-tree-select-tree-node-content-wrapper {
					background-color: transparent;
					box-shadow: none;
				}
			}
		}

		&-switcher-icon {
			display: flex;

			&::after {
				font-family: "icomoon" !important;
				content: '\e911';
				display: inline-block;
				border: unset;
				border-top-color: $gray;
				font-size: 12px;
				width: unset;
				height: unset;
				transform: rotate(-90deg);
			}

			&--expanded {
				&::after {
					transform: rotate(0deg);
				}
			}
		}

		&-hidden {
			display: none;
		}

		&-slide-up-enter {
			&.rc-tree-select-dropdown-slide-up-enter-active {
				&.rc-tree-select-dropdown-placement-bottomLeft {
					animation-name: rcSelectDropdownSlideUpIn;
					animation-play-state: running;
				}

				&.rc-tree-select-dropdown-placement-topLeft {
					animation-name: rcSelectDropdownSlideDownIn;
					animation-play-state: running;
				}
			}
		}

		&-slide-up-appear {
			&.rc-tree-select-dropdown-slide-up-appear-active {
				&.rc-tree-select-dropdown-placement-bottomLeft {
					animation-name: rcSelectDropdownSlideUpIn;
					animation-play-state: running;
				}

				&.rc-tree-select-dropdown-placement-topLeft {
					animation-name: rcSelectDropdownSlideDownIn;
					animation-play-state: running;
				}
			}
		}

		&-slide-up-leave {
			animation-duration: 0.3s;
			animation-fill-mode: both;
			transform-origin: 0 0;
			opacity: 1;
			animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
			animation-play-state: paused;

			&.rc-tree-select-dropdown-slide-up-leave-active {
				&.rc-tree-select-dropdown-placement-bottomLeft {
					animation-name: rcSelectDropdownSlideUpOut;
					animation-play-state: running;
				}

				&.rc-tree-select-dropdown-placement-topLeft {
					animation-name: rcSelectDropdownSlideDownOut;
					animation-play-state: running;
				}
			}
		}
	}

	&-multiple &-selector {
		display: flex;
		flex-wrap: wrap;
		background: $white;
		border-radius: 5px;
		overflow: hidden;
		box-sizing: border-box;
		padding-left: 8px;

		.rc-tree-select-selection-item {
			flex: none;
			background: $gray-light;
			border-radius: 4px;
			margin-right: 2px;
			padding: 2px 5px;
		}

		.rc-tree-select-selection-item-disabled {
			cursor: not-allowed;
			opacity: 0.5;
		}

		.rc-tree-select-selection-overflow {
			display: flex;
			flex-wrap: wrap;
			width: 100%;
			padding: 3px 0;
			z-index: 100000;
		}

		.rc-tree-select-selection-overflow-item {
			flex: none;
			max-width: 100%;
			display: inline-flex;
			align-items: center;
			padding: 2px 0;
		}

		.rc-tree-select-selection-search {
			position: relative;
			max-width: 100%;
			padding: 2px 0;
		}

		.rc-tree-select-selection-search-input {
			padding: 0;
			font-family: inherit;
			border: none;
			outline: none;
			background: none;
			width: 100%;
		}

		.rc-tree-select-selection-item-remove-icon {
			margin-left: 5px;
		}

		.rc-tree-select-selection-placeholder {
			position: absolute;
			top: 50%;
			right: 11px;
			left: 8px;
			transform: translateY(-50%);
			transition: all 0.3s;
		}

		.rc-tree-select-selection-search-mirror {
			padding: 2px 5px;
			font-family: inherit;
			position: absolute;
			z-index: 999;
			white-space: nowrap;
			left: 0;
			top: 0;
			bottom: 0;
			visibility: hidden;
		}
	}

	&-focused &-selector {
		border-color: $gray !important;
	}
}

@keyframes rcSelectDropdownSlideUpIn {
	0% {
		opacity: 0;
		transform-origin: 0 0;
		transform: scaleY(0);
	}
	100% {
		opacity: 1;
		transform-origin: 0 0;
		transform: scaleY(1);
	}
}

@keyframes rcSelectDropdownSlideUpOut {
	0% {
		opacity: 1;
		transform-origin: 0 0;
		transform: scaleY(1);
	}
	100% {
		opacity: 0;
		transform-origin: 0 0;
		transform: scaleY(0);
	}
}

@keyframes rcSelectLoadingIcon {
	0% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(360deg);
	}
}


.rc-tree-select-single .rc-tree-select-selector {
	background: #F2F3F5;
}
