@import 'src/styles/variables';

.setting-store {
  background: white;
  @media (min-width: $xl) {
    padding: 20px;
    border: 2px solid #E5E6EB;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 2px solid $gray-background;
    @media (min-width: $xl) {
      padding-bottom: 15px;
    }

    .title {
      display: flex;
      align-items: center;

      span {
        margin-left: 10px;
      }
    }
  }
  &__content {
    margin-top: 10px;

    .setting-item {
      display: flex;
      flex-direction: column;

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
  &.main {
    background: white;
    .logo {
      width: 144px;
      height: 108px;

      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
        cursor: pointer;
        object-position: left;
      }
    }
    .work-hours {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 10px;
      }
      span {
        max-width: 46px;
        width: 100%;
        margin-right: 10px;
      }
      input {
        width: 100%;
        @media (min-width: $md) {
          width: 114px;
        }
      }
      label + label {
        margin-left: 10px;
        @media (min-width: $md) {
          margin-left: 20px;
        }
      }
    }
  }
  &.contacts {
    background: white;
    @media (min-width: $xl) {
      padding: 20px 20px 10px;
    }

    .added-data-item {
      border-bottom: 2px solid $gray-background;
      margin-top: 10px;
      padding-bottom: 10px;

      .field,
      .description {
        border: 2px solid #E5E6EB;
        padding: 10px;
        position: relative;
      }
      .field {
        padding-right: 30px;
        height: 33px;
        display: flex;
        align-items: center;

        &__icon {
          display: flex;
          align-items: center;
          position: absolute;
          right: 10px;
          top: 0;
          bottom: 0;
        }
      }
      .description {
        height: 70px;
      }
    }
  }
  &.faq {
    height: 100%;
    background: white;
  }
}
.navigation {
  padding: 20px 10px;

  ul {
    list-style: none;
    display: flex;

    li {
      margin-right: 24px;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
  &__item {
    cursor: pointer;
    padding: 10px;
    height: 33px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      transition: .3s;
    }
  }
}
.navigation-mobile {
  white-space: nowrap;

  li {
    &.active {
      button {
        color: $deep-black;
        background: $white;
      }
    }
  }
}
.btnCont {
  display: flex;
  gap: 16px;
}

.deleteShopContainer {
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  div {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    span {
      margin-bottom: 10px;
    }
  }
  .deleteBtn {
    display: flex;
    flex-direction: row;
    padding-left: 8px;
  }
}

.paymentCard {
  display: block;

  &__logo {
    display: block;
    height: 50px;
    aspect-ratio: 211 / 50;
    margin-bottom: 16px;
  }
}
