@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?mwdgo');
  src:  url('fonts/icomoon.eot?mwdgo#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?mwdgo') format('truetype'),
    url('fonts/icomoon.woff?mwdgo') format('woff'),
    url('fonts/icomoon.svg?mwdgo#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-catalog:before {
  content: "\e901";
}
.icon-windowed:before {
  content: "\e92c";
}
.icon-expand:before {
  content: "\e92d";
}
.icon-tablet-revert:before {
  content: "\e92e";
}
.icon-tablet:before {
  content: "\e92f";
}
.icon-preview:before {
  content: "\e92b";
}
.icon-ellipsis:before {
  content: "\e929";
}
.icon-replay:before {
  content: "\e92a";
}
.icon-arrow-back:before {
  content: "\e928";
}
.icon-exit:before {
  content: "\e927";
}
.icon-laptop:before {
  content: "\e922";
}
.icon-fire:before {
  content: "\e923";
}
.icon-phone:before {
  content: "\e924";
}
.icon-tv:before {
  content: "\e925";
}
.icon-star:before {
  content: "\e921";
}
.icon-faq:before {
  content: "\e902";
}
.icon-hints:before {
  content: "\e91a";
}
.icon-clients:before {
  content: "\e91b";
}
.icon-delivery:before {
  content: "\e91c";
}
.icon-seo:before {
  content: "\e91d";
}
.icon-orders:before {
  content: "\e91e";
}
.icon-payment:before {
  content: "\e91f";
}
.icon-rate:before {
  content: "\e920";
}
.icon-plus:before {
  content: "\e919";
}
.icon-lock:before {
  content: "\e918";
}
.icon-question-border:before {
  content: "\e915";
}
.icon-contacts:before {
  content: "\e916";
}
.icon-requisites:before {
  content: "\e917";
}
.icon-arrow-increase:before {
  content: "\e913";
}
.icon-arrow-decrease:before {
  content: "\e914";
}
.icon-info-border:before {
  content: "\e912";
}
.icon-arrow-down:before {
  content: "\e911";
}
.icon-arrow-right:before {
  content: "\e905";
}
.icon-menu:before {
  content: "\e910";
}
.icon-search:before {
  content: "\e90f";
}
.icon-arrow-up:before {
  content: "\e90e";
}
.icon-arrow-left:before {
  content: "\e926";
}
.icon-close:before {
  content: "\e90d";
}
.icon-arrow-up-border:before {
  content: "\e90c";
}
.icon-edit:before {
  content: "\e90a";
}
.icon-delete:before {
  content: "\e90b";
}
.icon-eye:before {
  content: "\e909";
}
.icon-settings:before {
  content: "\e900";
}
.icon-finance:before {
  content: "\e903";
}
.icon-visibility:before {
  content: "\e904";
}
.icon-banners:before {
  content: "\e906";
}
.icon-dashboard:before {
  content: "\e907";
}
.icon-reviews:before {
  content: "\e908";
}
.icon-filters:before {
  content: "\e993";
}
