@import 'src/styles/variables'

$disabled-color: #ddd

.toggle-switch
	position: relative
	width: 40px
	height: 21px
	display: inline-block
	vertical-align: middle
	user-select: none
	text-align: left

	&-checkbox
		display: none

	&-checkbox:checked + &-label
		background-color: $blue

		.inner
			margin-left: 0

		.switch
			right: 0 !important

	&-label
		display: block
		height: 100%
		overflow: hidden
		cursor: pointer
		border-radius: 10px
		background-color: $gray-light
		outline: none

		.inner
			display: block
			width: 200%
			height: 100%
			margin-left: -100%
			transition: margin 0.3s ease-in 0s
			font-size: 8px
			padding: 4px

			&:before,
			&:after
				display: inline-flex
				align-items: center
				vertical-align: top
				width: 50%
				height: 100%
				padding: 0
				color: $white
				box-sizing: border-box

			&:after
				content: attr(data-no)
				justify-content: flex-end

			&:before
				content: attr(data-yes)

		.switch
			display: block
			width: 17px
			height: 17px
			margin: 2px
			background: $white
			position: absolute
			top: 0
			bottom: 0
			right: 19px
			border-radius: 20px
			transition: all 0.3s ease-in 0s

	&-disabled
		background-color: $disabled-color
		cursor: not-allowed


	&.small-switch
		width: 38px
		height: 19px

		.inner
			&:before,
			&:after
				line-height: 19px

		.switch
			width: 15px
			height: 15px

	&.square-switch
		height: 25px
		width: 61px

		.toggle-switch-label
			border-radius: 0

			.switch
				border-radius: 0
				width: 29px
				height: 23px
				margin: 1px
				right: 30px
