@import 'src/styles/variables'

.sidebar
	height: 100vh
	position: fixed
	top: 0
	bottom: 0
	left: -100px
	width: 90px
	background: #0094E7
	z-index: 11
	box-shadow: 0 6px 15px rgba(0, 0, 0, 0.08)
	transition: all .2s ease-out
	padding: 15px 0
	display: flex
	flex-direction: column
	align-items: center

	&.active
		left: 0

	&__logo
		margin-bottom: 20px
		max-width: 45px
		max-height: 45px
		width: 45px
		height: 100%

		img
			width: 100%
			height: 100%
			object-fit: contain

		.default-logo
			background: #006296
			border-radius: 5px
			height: 100%
			display: flex
			align-items: center
			justify-content: center

	&__menu-wrapper
		overflow-y: auto
		margin-bottom: 70px

	&__toggle
		position: fixed
		bottom: 30px
		left: 30px


.menu
	&__item
		margin-bottom: 15px
		width: 84px
		display: flex
		justify-content: center

		.link
			display: flex
			align-items: center
			justify-content: center
			height: 60px
			width: 60px
			background: #006296
			border-radius: 50%
			transition: .3s

			&.active, &:hover
				background: $red
