@import 'src/styles/variables'

.statistics
	border: 2px solid #E5E6EB
	background: white
	padding: 10px
	display: flex
	align-items: center
	justify-content: space-between
	@media (min-width: $xl)
		padding: 10px 20px 10px 10px
		height: 80px

	&__icon
		max-width: 45px
		width: 100%
		height: 45px
		background: $blue
		display: flex
		align-items: center
		justify-content: center


.new-clients
	border: 2px solid #E5E6EB
	background: white
	padding: 10px
	padding-top: 0px
	padding-bottom: 0px
	display: flex
	justify-content: space-between
	flex-direction: column
	margin-top: 10px
	@media (min-width: $md)
		height: 80px
		flex-direction: row
		align-items: center
		margin-top: 0

	&__list
		padding-bottom: 10px
		margin-bottom: 10px
		border-bottom: 2px solid $gray-background
		@media (min-width: $md)
			padding-bottom: 0
			margin-bottom: 0
			border-bottom: none
			width: 50%
		@media (min-width: $xl)
			width: 47%
		@media (min-width: $xxl)
			width: 50%

		p + p
			margin-top: 5px

	&__users
		flex: 1
		height: 100%
		display: flex
		align-items: center
		justify-content: space-between
		@media (min-width: $md)
			padding-left: 10px

		.logo
			max-width: 45px
			width: 100%
			height: 45px
			background: $blue
			display: flex
			align-items: center
			justify-content: center
			@media (min-width: $xl)
				margin-right: 10px
			@media (min-width: $xxl)
				margin-left: 30px

		.counter
			text-align: center

			p + p
				margin-top: 15px

			hr
				margin-top: 7px
				height: 3px
				background: $blue


.recently-sold
	margin-top: 20px
	border: 2px solid #E5E6EB
	padding: 10px 10px 0
	background: white
	@media (min-width: $xl)
		padding: 10px


	&__item
		text-align: center
		display: flex
		flex-direction: column
		align-items: center

		.title
			margin-top: 10px
			height: 40px
			-webkit-line-clamp: 2
			display: -webkit-box
			-webkit-box-orient: vertical
			overflow: hidden

		&Img
			width: 100%
			aspect-ratio: 1/1
			overflow: hidden

			img
				object-fit: contain
				width: 100%
				height: 100%
				vertical-align: top

		hr
			margin-top: 10px
			width: 100%
			@media (min-width: $xl)
				max-width: 132px


.sold-items-graphic
	margin-top: 20px
	border: 2px solid #E5E6EB
	background: white
	padding: 10px
	@media (min-width: $xl)
		margin-top: 20px
		padding: 20px 20px 20px 10px

	&__today
		margin-top: 7px

	&__chart
		height: 310px
		margin-top: 15px
		margin-left: -30px
		margin-bottom: -10px
		@media (min-width: $xl)
			margin-top: 50px
			margin-left: 0
			margin-bottom: 0


.general-info-item
	border: 2px solid #E5E6EB
	background: white
	height: 94px
	padding: 10px
	display: flex
	align-items: center
	justify-content: space-around
	flex-direction: column

	p
		font-size: 40px
		line-height: 40px

	@media (min-width: $xl)
		height: 151px
		padding: 20px 20px 20px 10px
		justify-content: center
		p
			font-size: 30px
	@media (min-width: $xxl)
		height: 139px
		p
			font-size: 40px
			line-height: 60px
	@media (min-width: 1800px)
		height: 155px


	&__desc
		position: relative
		display: flex
		margin-top: 10px

	&__changes
		display: flex
		align-items: flex-start
		font-size: 10px
		line-height: 16px
		margin-left: 5px

.new-stat-block
	display: grid
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr
	gap: 10px
	@media (max-width: 1400px)
		grid-template-columns: 1fr
	@media (max-width: 600px)
		grid-template-columns: 1fr



